<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem">
          <div @click="createItem" class="Button1">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Adaugă tip de instituție
            </span>
          </div>
        </div>
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="'INSTITUTION_TYPE'"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @Btn2="editItem"
          @Btn3="deleteItem"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/institutionTypePermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { INSTITUTION_TYPE } from "../api.js";
import DialogModal from "@/components/DialogModal";
import TableParent from "@/components/TableParent";
import InstitutionTypeOrganizationalStructureType from "@/components/InstitutionTypeOrganizationalStructureType";

export default {
  name: "InstitutionObjectives",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
  },
  data() {
    return {
      tableHead: [
        {
          title: "Nume",
          queryKey: "name",
          bottomHead: {
            quikFiltre: true,
          },
        },
      ],
      tableModule: INSTITUTION_TYPE.get,
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    reload() {
      this.reloadTable++;
    },

    initHeadTable() {
      const permMap = {
        instName: ["superadmin"],
      };

      const role = this.getUserRole();

      Object.entries(permMap).forEach((e) => {
        const findHead = this.tableHead.find((head) => head.meta == e[0]);
        if (findHead && e[1].includes(role)) {
          findHead.show = true;
        }
      });
    },
    checkTableHeader(meta) {
      const find = this.tableHead.find((e) => e.meta == meta);
      if (find?.show !== false) {
        return true;
      }
      return false;
    },

    prepareData(row) {
      const preparation = [];
      preparation.push(row.name || "-");

      const x = this.PERMISIONS;

      const prepareBtn = [];

      if (x.edit === true) {
        prepareBtn.push(2);
      }
      if (x.delete === true) {
        prepareBtn.push(3);
      }

      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return preparation;
    },
    createItem() {
      this.$modal.show(
        DialogModal,
        {
          target: "AddInstitutionType",
          title: "Adaugă tipul instituției",
          inputs: [
            {
              id: "institutionType",
              type: "component",
              component: InstitutionTypeOrganizationalStructureType,
              componentProps: { initial: null },
              errReason: "",
            },
          ],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Adaugă",
            handler: (sendData) => {

              const error = (msg) => {
                this.$toastr.e(
                  msg || "Adăugarea tipului de instituție a eșuat."
                );
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              INSTITUTION_TYPE.add(sendData.institutionType)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Tipul de instituție a fost adăugat.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "AddInstitutionType",
          adaptive: true,
          width: "550",
          height: "450",
        }
      );
    },
    editItem(data) {
      if (!Number.isInteger(data.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "EditInstitutionType",
          title: "Modifică tipului de instituție",
          inputs: [
            {
              id: "institutionType",
              type: "component",
              component: InstitutionTypeOrganizationalStructureType,
              componentProps: { initial: data },
              errReason: "Nu a fost încărcat nici un fișier.",
            },
          ],
          closeBtnText: "Anulează",
          acceptFinish(data) {
            const institutionType = data.institutionType;

            if (institutionType.organizationalStructureType.length == 0) {
              this.$toastr.w("Nu a fost selectat nici un tip de structură.");
              return false;
            }

            return true;
          },
          button: {
            type: 2,
            value: "Modifică",
            handler: (sendData) => {
              const error = (msg) => {
                this.$toastr.e(
                  msg || "Adăugarea tipului de instituție a eșuat."
                );
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              INSTITUTION_TYPE.edit(data.id, sendData.institutionType)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s(
                      "Datele tipului de instituție au fost modificate."
                    );
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "EditInstitutionType",
          adaptive: true,
          width: "550",
          height: "450",
        }
      );
    },

    deleteItem(data) {
      if (!Number.isInteger(data?.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "DeleteInstitutionType",
          title: "Șterge tipului de instituție",
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi tipul de instituție?</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(
                  msg || "Ștergerea tipului de instituție a eșuat."
                );
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              INSTITUTION_TYPE.delete(data.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Tipului de instituție a fost șters.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "DeleteInstitutionType",
          adaptive: true,
          width: "550",
          height: "400",
        }
      );
    },
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    const canViewButtonsColumn = ["edit", "delete"];
    this.initHeadTable();
    this.updateRoleStatus();
    if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }
  },
};
</script>
